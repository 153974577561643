import React from 'react'
import './Landing.css'
import phone from './phone1.png'
import appStore from './app-store.png'
import logo from './logo-black.png'

export default function Landing() {
  return (
    <div id='landing'>

        <div id='landing-info'>
            <img id='logo' src={logo}/>

            <h1 style={{color:'#ef6350', fontFamily: 'Dosis', textAlign:'center', fontSize: 54}}>Never lose a family recipe again.</h1>

            <p style={{fontFamily: 'Campton', fontSize: 22, lineHeight: 1.5}}>View, learn and share recipes and connect with friends and family. Download the app today!</p>

            <div>
                <a  href='https://apps.apple.com/gb/app/cuk-buk/id6473445329'>
                    <img className='app-link' style={{padding: '12px 0'}} src={appStore} alt='App store'/>
                </a>
                    
                <a href='https://play.google.com/store/apps/details?id=com.cukbuk.cukbuk&pcampaignid=web_share&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
                    <img className='app-link' alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/>
                </a>

            </div>
        </div>

        <div id='phone-container'>
            <img id='phone' src={phone}/>

            <footer id='landing-footer'>

            </footer>
        </div>


        <a id='frantech-link' href='https://www.frantech.org.uk'>A Frantech App</a>
        
       
    </div>
  )
}
